import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import { navigate } from "gatsby"
import { authService } from "../../services/auth.service"
import { CpaService } from "../../services/cpa.service"

export default function Account() {
  // const { user } = useContext(AuthContext)

  const [cpa, setCpa] = useState()

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    syfirRole: "",
  })

  let user = authService.getUser()
  let uid = user.uid

  useEffect(() => {
    async function init() {
      let cpaData = await CpaService.getAllCpa(uid)
      if (cpaData !== undefined) setCpa(cpaData[0])

      let data = await authService.getUserById(uid)
      if (data !== undefined) {
        setValues({
          // ...values,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          syfirRole: data.syfirRole,
        })

        authService.setLocalUser(data)

        // console.log({ ...values })
      }

      //console.log("rander")
    }

    init()
  }, [])

  const InsDashCard = () => (
    <div className="pt-5 " onClick={() => navigate("/app/instrument")}>
      <div className="pt-5 bg-black rounded-xl  flex items-center shadow-lg hover:shadow-2xl transform hover:scale-105 duration-500 ">
        <div>
          <div className="pl-5 text-xl font-bold text-white">
            Instrument Management Dashboard
          </div>
          <p className="pl-5  pb-5 font-semibold text-gray-300">
            Click to enter
          </p>
        </div>
      </div>
    </div>
  )

  return (
    <Layout>
      <br />
      <div className="mb-0 max-w-xl bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg mb-2 leading-6 font-medium text-gray-900">
            Account
          </h3>
          <p className="mt-1 mb-0 text-sm text-gray-500">
            Personal details and application.
          </p>
        </div>
        <div className="border-t border-gray-200 ">
          <dl className="mb-0 ">
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 ">First Name</dt>
              <dd className="mt-1 text-sm text-gray-900 mb-0 sm:mt-0 sm:col-span-2">
                {values.firstName}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Last Name</dt>
              <dd className="mt-1 text-sm text-gray-900 mb-0 sm:mt-0 sm:col-span-2">
                {values.lastName}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Email address
              </dt>
              <dd className="mt-1 text-sm text-gray-900 mb-0 sm:mt-0 sm:col-span-2">
                {values.email}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Content Provider Application
              </dt>
              <dd className="mt-1 text-sm text-gray-900 mb-0 sm:mt-0 sm:col-span-2">
                {cpa !== undefined ? (
                  <div>
                    Status:{" "}
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                      {cpa.status}
                    </span>
                    <br />
                    {cpa.message}
                  </div>
                ) : null}

                {cpa === undefined || cpa.status === "Declined" ? (
                  <button
                    className="font-medium text-indigo-500 hover:text-indigo-600"
                    onClick={() => {
                      navigate("/app/cpa")
                    }}
                  >
                    Apply
                  </button>
                ) : null}
              </dd>
            </div>

            {/* <div className="container w-50 mx-auto cursor-pointer  bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl transform hover:scale-105 duration-500">
              <img className="w-full" src={imgUrl} alt="" />
              <div className="text-center relative pb-8 px-3">
                <h1 className="mb-1 text-xl font-semibold text-gray-700 hover:text-gray-900 ">
                  {title}
                </h1>
                <span className=" text-gray-700 hover:text-gray-900">
                  {content}
                </span>
              </div>
            </div> */}

            {/* <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">
          Attachments
        </dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 ">
          <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                </svg>
                <span className="ml-2 flex-1 w-0 truncate">
                  resume_back_end_developer.pdf
                </span>
              </div>
              <div className="ml-4 flex-shrink-0">
                <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                  Download
                </a>
              </div>
            </li>
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
                </svg>
                <span className="ml-2 flex-1 w-0 truncate">
                  coverletter_back_end_developer.pdf
                </span>
              </div>
              <div className="ml-4 flex-shrink-0">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Download
                </a>
              </div>
            </li>
          </ul>
        </dd>
      </div> */}
          </dl>
        </div>
      </div>

      {values.syfirRole === "cpr" ? <InsDashCard /> : null}
    </Layout>
  )
}
