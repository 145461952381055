import firebase from "gatsby-plugin-firebase"
// import { firestore } from "../utils/firebase"

export const CpaService = {
  createCpa,
  getAllCpa,
}

async function createCpa(data) {
  data["createTime"] = firebase.firestore.FieldValue.serverTimestamp()
  let docId
  await firebase
    .firestore()
    .collection("cpa")
    .add(data)
    .then(async docRef => {
      console.log("Document successfully written!")
      docId = docRef.id
    })
    .catch(error => {
      console.error("Error writing document: ", error)
    })

  return docId
}

async function getAllCpa(uid) {
  const data = []
  await firebase
    .firestore()
    .collection("cpa")
    .orderBy("createTime", "desc")
    .where("uid", "==", uid)
    .limit(1)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data())
        data.push(doc.data())
      })
    })
    .catch(error => {
      console.error("Error getting document: ", error)
    })

  return data
}

// const getCpa = async () => {
//   const data = []

//   const snapshot = await firestore
//     .collection("cpa")
//     .where("uid", "==", uid)
//     .limit(1)
//     .get()

//   snapshot.docs.forEach(doc => data.push(doc.data))
// }

// export { getCpa }

// async function uploadFile(file) {
//   var ref = firebase.storage().ref("/cpa/")
//   return await ref.put(file).then(async data => {
//     return await ref.getDownloadURL().then(async url => {
//       return url
//     })
//   })
// }
