import * as React from "react"
//import { useContext } from "react"
//import { AuthContext } from "../context/auth"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import NavMenu from "./navMenu"
// import Hamburger from "../images/Hamburger_icon_white.svg"

const Header = () => {
  // const { user } = useContext(AuthContext)

  // const menu = (
  //   <Menu>
  //     <Menu.Item key="1">
  //       <Link to="/app/account">Account</Link>
  //     </Menu.Item>

  //     <Menu.Item key="0">
  //       <button onClick={handleLogout}>Logout</button>
  //     </Menu.Item>
  //   </Menu>
  // )

  return (
    <header className=" bg-black flex md:justify-center items-center sm:justify-start ">
      {/* style={{height: '78px'}} */}

      <div className="">
        <Link to="/">
          <StaticImage
            src="../images/imir_logo.png"
            width={80}
            quality={100}
            alt="CCI IMIR Logo"
          />

          {/* <span> </span>

          <StaticImage
            src="../images/ficec-logo.png"
            width={90.5}
            quality={100}
            alt="FICEC Logo"
          /> */}
        </Link>
      </div>
      {/* <div className=" absolute right-2 ">
        <NavMenu />
      </div> */}

      {/* <div>
        <h1 className="text-white pt-3">{siteTitle}</h1>
      </div> */}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
